import React from 'react';

const ExternalLink = ({ href, children, style }) => (
  <a style={{ ...style }} href={href} target="_blank" rel="noopener noreferrer">{children}</a>
);

ExternalLink.defaultProps = {
  href: '/',
};

export default ExternalLink;
