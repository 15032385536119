import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import LogoVoox from "../svg/logoVooxWhite"
import LogoIg from "../svg/logoIg"
import LogoFb from "../svg/logoFb"
import LogoTw from "../svg/logoTw"
import LogoLine from "../svg/logoLine"

const Spacer = styled.div`
  flex-grow: 1;
`

const LogoContainer = styled.div`
  position: absolute;
  display: flex;
  flex-grow: 1;
  left: 0;
  right: 0;
  justify-content: center;
  margin: 0 25px;
`

const LogoFixedWidth = styled.div`
  width: 100%;
  max-width: 980px;
`

const LinkBar = () => (
  <header
    style={{
      position: "relative",
      padding: "0 22px",
      height: "50px",
      backgroundColor: "black",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    }}
  >
    <LogoContainer>
      <LogoFixedWidth>
        <LogoVoox />
      </LogoFixedWidth>
    </LogoContainer>
    {/* <LogoVoox /> */}
    {/* <Spacer /> */}
    <div
      style={{
        zIndex: 100,
      }}
    >
      <LogoIg />
    </div>
    <div
      style={{
        zIndex: 100,
      }}
    >
      <LogoTw />
    </div>
    <div
      style={{
        zIndex: 100,
      }}
    >
      <LogoFb />
    </div>
    <div
      style={{
        zIndex: 100,
      }}
    >
      <LogoLine />
    </div>
  </header>
)

export default LinkBar
