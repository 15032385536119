import React from "react"
import ExternalLink from "../components/externalLink"
import styled from "styled-components"

const Container = styled.div`
  padding: 0 8px;
  transition: 0.15s ease-in-out opacity;

  &:hover {
    opacity: 0.5;
  }
`

const LogoFb = () => (
  <Container>
    <ExternalLink href="https://www.facebook.com/VOOX.JP">
      <svg
        display="block"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 0C6.26381 0 0 6.26381 0 14C0 21.7362 6.26381 28 14 28C21.7362 28 28 21.7362 28 14C28 6.26381 21.7362 0 14 0ZM17.8681 8.55972H15.8217C15.4973 8.55972 15.0232 8.7344 15.0232 9.43316V11.3298H17.8431L17.5187 14.549H15.0232V23.7077H11.2299V14.549H9.43316V11.3298H11.2299V9.25847C11.2299 7.76114 11.9287 5.44029 15.0481 5.44029H17.8431V8.55972H17.8681Z"
          fill="white"
        />
      </svg>
    </ExternalLink>
  </Container>
)

export default LogoFb
