import React, { useEffect, useState, useRef, useCallback }  from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import styled from "styled-components"
import ExternalLink from "./externalLink"
import Modal from "../components/modal"

import LogoIg from "../svg/logoIg"
import LogoFb from "../svg/logoFb"
import LogoTw from "../svg/logoTw"
import LogoLine from "../svg/logoLine"
import { ModalType } from "../types"

const Container = styled.footer`
  background: black;
  height: 290px;
  ${"" /* height: 375px; */}
`

const LinkHover = styled.div`
  transition: 0.15s ease-in-out opacity;

  &:hover {
    opacity: 0.5;
  }
`

const InternalLink = styled.div`
  &:hover {
    cursor: pointer;
  }
`

const Copy = styled.div`
  margin-top: 40px;
  font-size: 9pt;
`

const Overlay = styled.div`
  display: ${({ modalState }) => (modalState != null ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
`

const Footer = ({ sizes }) => {
  const [modalState, setModalState] = useState(null);
  return (
    <Container>
      <div
        style={{
          paddingTop: 66,
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <LogoIg />
        <LogoTw />
        <LogoFb />
        <LogoLine />
      </div>

      {/* <div style={{paddingTop: 66, display: 'flex', width: '100%', justifyContent: 'center'}}>
    <input style={{width:321}} type="email" id="email" pattern=".+@globex.com" size="30" placeholder="メールアドレスを登録" required />
    <button style={{marginLeft: 12, width: 190, height: 42}}>お得な情報を受け取る</button>
  </div> */}

      <div
        style={{
          letterSpacing: "0.05em",
          fontSize: "13px",
          fontWeight: 300,
          paddingTop: 25,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
        }}
      >
        <LinkHover style={{ margin: "10px" }}>
          <ExternalLink
            style={{ textDecoration: "none", color: "white" }}
            href="https://www.mooncreativelab.com/"
          >
            運営会社
          </ExternalLink>
        </LinkHover>
        <Copy>&copy;︎2021 Moon Creative Lab</Copy>
      </div>
      <Overlay modalState={modalState}>
        <Modal
          setModalState={setModalState}
          modalState={modalState}
          sizes={sizes}
        />
      </Overlay>
    </Container>
  )
}

export default Footer
