import React from "react"
import PropTypes from "prop-types"
import ExternalLink from "../components/externalLink"
import styled from "styled-components"

const Container = styled.div`
  padding: 0 8px;
  height: 28px;
  transition: 0.15s ease-in-out opacity;

  &:hover {
    opacity: 0.5;
  }
`

const LogoLine = ({ href }) => (
  <Container>
    <ExternalLink href={href}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        fill="none"
        viewBox="0 0 28 28"
      >
        <g fill="#fff" clipPath="url(#clip1)">
          <path d="M14 0a14 14 0 100 28 14 14 0 000-28zm7.317 17.808c-1.95 2.259-6.323 4.989-7.317 5.406-.994.418-.847-.266-.807-.501l.133-.796c.055-.28.047-.567-.026-.842-.103-.26-.516-.395-.819-.467-4.48-.583-7.793-3.712-7.793-7.448C4.688 8.993 8.867 5.6 14 5.6c5.133 0 9.312 3.393 9.312 7.56 0 1.666-.646 3.169-1.997 4.648h.002z"></path>
          <path d="M12.108 11.146h-.654a.182.182 0 00-.182.18v4.058a.18.18 0 00.113.168.18.18 0 00.07.014h.653a.182.182 0 00.182-.182v-4.058a.182.182 0 00-.182-.18M16.604 11.146h-.653a.182.182 0 00-.182.18v2.41l-1.867-2.51-.007-.026v-.012h-.789a.182.182 0 00-.182.18v4.016a.182.182 0 00.182.182h.66a.179.179 0 00.18-.182v-2.408l1.867 2.513a.206.206 0 00.047.044h.025a.146.146 0 00.047 0h.653a.182.182 0 00.182-.182v-4.025a.182.182 0 00-.182-.18M10.533 14.548H8.759v-3.222a.182.182 0 00-.182-.18h-.644a.182.182 0 00-.182.18v4.058c.001.047.02.092.052.126a.177.177 0 00.126.051h2.604a.182.182 0 00.182-.182v-.653a.182.182 0 00-.182-.182M20.211 12.161a.18.18 0 00.167-.112.18.18 0 00.013-.07v-.65a.18.18 0 00-.18-.183h-2.61a.17.17 0 00-.124.052.182.182 0 00-.05.123v4.063a.179.179 0 00.052.126.175.175 0 00.124.05h2.608a.179.179 0 00.18-.181v-.653a.18.18 0 00-.18-.182h-1.778v-.68h1.776a.18.18 0 00.18-.181v-.654a.18.18 0 00-.18-.182h-1.776v-.686h1.778z"></path>
        </g>
        <defs>
          <clipPath id="clip1">
            <path fill="#fff" d="M0 0H28V28H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </ExternalLink>
  </Container>
)

LogoLine.defaultProps = {
  href: "https://lin.ee/JcB9Bo5",
}

export default LogoLine
