import React from "react"
import ExternalLink from "../components/externalLink"
import styled from "styled-components"

const Container = styled.div`
  padding: 0 8px;
  transition: 0.15s ease-in-out opacity;

  &:hover {
    opacity: 0.5;
  }
`

const LogoIg = () => (
  <Container>
    <ExternalLink href="https://www.instagram.com/voox_jp/">
      <svg
        display="block"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.6797 14C16.6797 15.482 15.482 16.6797 14 16.6797C12.518 16.6797 11.3203 15.482 11.3203 14C11.3203 12.518 12.518 11.3203 14 11.3203C15.482 11.3203 16.6797 12.518 16.6797 14Z"
          fill="white"
        />
        <path
          d="M20.2672 9.25833C20.1359 8.90833 19.9336 8.59114 19.6656 8.33411C19.4086 8.06614 19.0914 7.86379 18.7414 7.73254C18.457 7.62317 18.0304 7.49192 17.2484 7.45364C16.4008 7.41536 16.1437 7.40442 14 7.40442C11.8508 7.40442 11.5992 7.40989 10.7515 7.45364C9.96951 7.49192 9.54294 7.62317 9.25857 7.73254C8.90857 7.86379 8.59138 8.06614 8.33435 8.33411C8.06638 8.59114 7.86404 8.90833 7.73279 9.25833C7.62341 9.5427 7.49216 9.96926 7.45388 10.7513C7.4156 11.599 7.40466 11.8505 7.40466 13.9997C7.40466 16.1435 7.4156 16.4005 7.45388 17.2482C7.49216 18.0302 7.61794 18.4568 7.73279 18.7411C7.86404 19.0911 8.06638 19.4083 8.33435 19.6654C8.59138 19.9333 8.90857 20.1357 9.25857 20.2669C9.54294 20.3763 9.96951 20.5075 10.7515 20.5458C11.5992 20.5841 11.8508 20.595 14 20.595C16.1492 20.595 16.4008 20.5896 17.2484 20.5458C18.0304 20.5075 18.457 20.3818 18.7414 20.2669C19.4414 19.999 19.9992 19.4411 20.2672 18.7411C20.3765 18.4568 20.5078 18.0302 20.5461 17.2482C20.5844 16.4005 20.5953 16.1435 20.5953 13.9997C20.5953 11.8505 20.5898 11.599 20.5461 10.7513C20.5078 9.96926 20.3765 9.5427 20.2672 9.25833ZM14 18.1286C11.7195 18.1286 9.87107 16.2802 9.87107 13.9997C9.87107 11.7193 11.7195 9.87083 14 9.87083C16.2804 9.87083 18.1289 11.7193 18.1289 13.9997C18.1289 16.2802 16.2804 18.1286 14 18.1286ZM18.2929 10.6747C17.7625 10.6747 17.3304 10.2427 17.3304 9.71223C17.3304 9.18176 17.7625 8.74973 18.2929 8.74973C18.8234 8.74973 19.2554 9.18176 19.2554 9.71223C19.2554 10.2427 18.8234 10.6747 18.2929 10.6747Z"
          fill="white"
        />
        <path
          d="M14 0C6.26719 0 0 6.26719 0 14C0 21.7328 6.26719 28 14 28C21.7328 28 28 21.7328 28 14C28 6.26719 21.7328 0 14 0ZM21.9898 17.3141C21.9516 18.1672 21.8148 18.7523 21.618 19.2664C21.2023 20.3438 20.3492 21.2023 19.2664 21.618C18.7578 21.8148 18.1727 21.9516 17.3141 21.9898C16.4555 22.0281 16.182 22.0391 14 22.0391C11.818 22.0391 11.5445 22.0281 10.6859 21.9898C9.83281 21.9516 9.24766 21.8148 8.73359 21.618C8.19766 21.4156 7.71094 21.0984 7.31172 20.6883C6.90156 20.2891 6.58437 19.8023 6.38203 19.2664C6.18516 18.7578 6.04844 18.1727 6.01016 17.3141C5.97188 16.4555 5.96094 16.182 5.96094 14C5.96094 11.818 5.97188 11.5445 6.01016 10.6859C6.04844 9.83281 6.18516 9.24766 6.38203 8.73359C6.58437 8.19766 6.90156 7.71094 7.31172 7.31172C7.71094 6.90156 8.19766 6.58437 8.73359 6.38203C9.24766 6.18516 9.82734 6.04844 10.6859 6.01016C11.5445 5.97188 11.818 5.96094 14 5.96094C16.182 5.96094 16.4555 5.97188 17.3141 6.01016C18.1672 6.04844 18.7523 6.18516 19.2664 6.38203C19.8023 6.58437 20.2891 6.90156 20.6883 7.31172C21.0984 7.71094 21.4156 8.19766 21.618 8.73359C21.8148 9.24766 21.9516 9.82734 21.9898 10.6859C22.0281 11.5445 22.0391 11.818 22.0391 14C22.0391 16.182 22.0281 16.4555 21.9898 17.3141Z"
          fill="white"
        />
      </svg>
    </ExternalLink>
  </Container>
)

export default LogoIg
