import React from 'react';
import PropTypes from 'prop-types';
import ExternalLink from '../components/externalLink';
import styled from 'styled-components';

const Container = styled.div`
  padding: 0 8px;
  transition: 0.15s ease-in-out opacity;

  &:hover {
    opacity: 0.5;
  }
`;

const LogoTw = ({href}) => (
  <Container>
    <ExternalLink href={href}>
      <svg display="block" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 0C6.26381 0 0 6.26381 0 14C0 21.7362 6.26381 28 14 28C21.7362 28 28 21.7362 28 14C28 6.26381 21.7362 0 14 0ZM20.9127 11.8039C20.9127 11.9537 20.9127 12.1034 20.9127 12.2282C20.9127 16.6453 17.5437 21.7611 11.3797 21.7611C9.48307 21.7611 7.73619 21.2121 6.23886 20.2638C6.48841 20.2888 6.76292 20.3137 7.03743 20.3137C8.60963 20.3137 10.057 19.7897 11.205 18.8913C9.73262 18.8663 8.5098 17.893 8.08556 16.5704C8.28521 16.6203 8.5098 16.6203 8.70945 16.6203C9.00891 16.6203 9.30838 16.5704 9.58289 16.4955C8.06061 16.1961 6.8877 14.8235 6.8877 13.2264C6.8877 13.2014 6.8877 13.2014 6.8877 13.1765C7.3369 13.426 7.86096 13.5758 8.40998 13.6007C7.51159 13.0018 6.91266 11.9786 6.91266 10.8057C6.91266 10.1818 7.08734 9.60784 7.36185 9.13369C9.00891 11.1551 11.4795 12.5027 14.2496 12.6275C14.1996 12.3779 14.1747 12.1283 14.1747 11.8538C14.1747 10.0071 15.672 8.5098 17.5187 8.5098C18.492 8.5098 19.3405 8.90909 19.9643 9.55793C20.738 9.4082 21.4367 9.13369 22.0856 8.7344C21.836 9.50802 21.3119 10.1818 20.6132 10.5811C21.287 10.5062 21.9358 10.3316 22.5348 10.057C22.1355 10.7308 21.5615 11.3298 20.9127 11.8039Z" fill="white" />
      </svg>
    </ExternalLink>
  </Container>
);

LogoTw.defaultProps = {
  href: 'https://twitter.com/VOOX_jp',
};

export default LogoTw;
