import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { func } from 'prop-types';
import { ModalType } from '../types';

const Container = styled.div`
  overflow-y: scroll;
  position: relative;
  background-color: white;
  width: 840px;
  height: 559px;
  &::before {
    content: "";
    background: linear-gradient(180deg, rgba(255,255,255,0) 60%, rgba(255,255,255,0.5) 100%);
    position: fixed;
    width: inherit;
    height: inherit;
    pointer-events: none;
  }

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { width: 95%; height: 100vh; margin: auto; }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

const CloseButton = styled.div`
  position: absolute;

  right: 29px;
  top: 21px;
  font-weight: 100px;
  font-size: 40px; 
  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

const TextBox = styled.div`
  width: 80%;
  height: 80%;
  margin: 40px auto;
`;

const Header = styled.header`
  font-family: Cormorant;
  font-size: 30px;
  line-height: 42px;
`;

const SubHeader = styled.section`
  font-size: 12px;
  font-weight: 600;
  margin-top: 32px;
  margin-bottom: 16px;
`

const Content = styled.section`
  font-size: 12px;
  margin-bottom: 16px;
`;

const SubContent = styled.section`
  font-size: 12px;
  margin-bottom: 12px;
`

const privacy = (
  <TextBox>
    <Header>プライバシーポリシー</Header>
    <br />
    <Content>
      Moon Creative Lab
      Inc.（以下「当社」といいます。）は、当社が運営する音声コンテンツの制作・配信サービスである「VOOX」（以下「本サービス」といいます。）における、お客様についての個人情報を含む全ての情報（以下「利用者情報」といいます。）の取り扱いについて、以下のとおりプライバシーポリシー（以下「本プライバシーポリシー」といいます。）を定め、利用者情報の保護に努めます。ご利用に際しては、本プライバシーポリシーの全文をお読みいただいたうえで、同意の上、本サービスをご利用ください。
      <br />
    </Content>
    <SubHeader>1.適用範囲</SubHeader>
    <Content>
      本プライバシーポリシーは、当社がVOOXに関して提供するすべてのサービスにおける利用者情報の取り扱いに適用されます。本プライバシーポリシーの定めに従い、利用者情報を他社が取得して取り扱う場合については、本プライバシーポリシーに記載のあるもののほか、当該他社のプライバシーポリシーが適用されます。
    </Content>
    <SubHeader>2.お客様にご提供いただく情報および主な取り扱い</SubHeader>
    <Content>
      本サービスをご利用いただくにあたり、以下の情報のご提供をお願いする場合があります。これらの情報は、お客様の意思に反して取得することはございません。ただし、ご提供いただけない場合、本サービスの一部または全部をご利用いただけない場合がございます。
    </Content>
    <SubHeader>2.1) アカウント登録情報</SubHeader>
    <Content>
      本サービスのご利用開始時に、登録情報として、当社所定の情報（氏名、ニックネーム、生年月日、性別、メールアドレスを含みます。）をご登録いただきます。これらの情報はお客様による本サービスへのログイン、アカウントの引継ぎ等、本サービス上におけるお客様の識別等に利用いたします。
    </Content>
    <SubHeader>
      2.2)
      お客様が、ソーシャルネットワークサービス等の外部サービスとの連携を許可する場合には、当該外部サービスの情報
    </SubHeader>
    <Content>
      お客様が、FacebookまたはTwitterなどの第三者が提供するソーシャルネットワーキングサービスと連携させて本サービスにアクセスする場合、お客様の許諾の内容に従って、性別、年齢、興味のある対象、地域やそのFacebook内の友人関係、Facebook内で「いいね！」しているものに関する情報なども入手します。これらの情報は、お客様ごとにカスタマイズされたコンテンツを提供する目的で利用いたします。
    </Content>
    <SubHeader>2.3) アンケート・キャンペーン等で取得する情報</SubHeader>
    <Content>
      アンケートやキャンペーン（以下「キャンペーン等」といいます。）およびそれらに関わる抽選および賞品発送、ご購入商品の発送等の目的で、氏名、住所、電話番号、メールアドレス、性別、生年月日等を取得する場合がございます。
    </Content>
    <SubHeader>2.4) お客様からのお問い合わせの際に取得する情報</SubHeader>
    <Content>
      お客様からのお問い合わせに際し、電話番号、メールアドレス、端末機種名、OS情報等を取得する場合がございます。これらの情報はお問い合わせに対する調査、返答、およびご本人確認等のために利用いたします。
    </Content>
    <SubHeader>2.5) 決済情報</SubHeader>
    <Content>
      本サービスを通じて商品購入や有料サービスをご利用いただく場合、お客様にご利用料金をお支払いいただくための決済情報をご入力いただく場合がございます。なお、お客様にご入力いただいた決済情報は、お客様がご選択された決済手段の決済代行業者に直接送信され、当社は、クレジットカード情報を自ら蓄積・保存することはございません。
    </Content>
    <SubHeader>
      3. 本サービスの利用に伴い収集される情報および主な取り扱い
    </SubHeader>
    <SubHeader>
      3.1)
      クッキー（Cookie）および広告識別子その他の他社モジュールを利用して収集する情報
    </SubHeader>
    <Content>
      本サービスでは、お客様の設定内容の保存等お客様の利便性向上のため、セッションの維持および保護等セキュリティのためにクッキーを使用しています。お客様はクッキーの使用可否を選択できますが、クッキーの保存をご承諾いただけない場合には、ログインが必要なサービス等、本サービスの一部をご利用いただけない場合がございます。
      また、広告の効果を計測し、より関心の強いご案内（当社または第三者ウェブサイト等において当社または第三者による行動ターゲティング広告を配信することを含みます。）をご提供するために、また、お客様が利用した本サービスに対する訪問回数および利用形態、お客様の規模等の把握によりより良いサービスを提供するために、クッキーおよび広告識別子（以下「クッキー等」といいます。）を利用いたします。クッキー等を利用して収集する情報を用いた計測結果等の特定の個人を識別することができない情報については、クッキー等を発行する他社が利用することがあります。また、本サービス上において他社から配信される広告には、当該他社が本サービス以外のサイトで収集した行動履歴等を利用した行動ターゲティング広告が含まれます。このため、本サービスではGoogle
      Analyticsを利用しています。
      他社モジュールによる行動ターゲティングを停止したい場合には、他社モジュールの提供者ごとに、オプトアウト手続が必要となります。オプトアウト手続については、お客様において、下記のリンク先から必要な手続きを行う必要があります。
      https://tools.google.com/dlpage/gaoptout?hl=ja
    </Content>
    <SubHeader>3.2) アクセスログ</SubHeader>
    <Content>
      お客様が本サービスを利用した際にIPアドレス、ブラウザ種類、ブラウザ言語等の情報が自動で生成、保存されます。また、これらの情報は利用者環境を分析し、より良いサービス提供のため、また正常なサービス提供を妨害する不正行為防止のために利用いたします。
    </Content>
    <SubHeader>3.3) サービス利用状況に関する情報</SubHeader>
    <Content>
      本サービスでは、お客様へのサービス提供のため、お客様が本サービスを通じて行ったスマートフォンアプリ上におけるまたはオンライン上における利用状況に関する情報を自動的に取得することがあります。例えば、お客様自身が他の利用者とコミュニケーションのためにコメント、音声などの情報（以下「コンテンツ」といいます。）を送信、投稿する場合、これらのコンテンツはシステム上当社サーバーを経由して送信され、コンテンツの送信相手やデータ形式、操作日時等もサーバー上に一時的に記録されます。また、記録される内容には、ユーザーの許諾内容に従って、Facebook又はTwitterなどの第三者が提供するソーシャルネットワーキングサービス上の情報や、本サービス上でタップ・クリックによってアクセスしたURL情報などが含まれる場合があります。
    </Content>
    <SubHeader>3.4) 機器情報</SubHeader>
    <Content>
      本サービスでは、お客様が利用される機器情報（OS、端末の個体識別情報、コンピュータ名、広告ID、言語設定等）を取得する場合がございます。また、取得した広告IDを当社がお客様に付与した内部識別子に紐付ける場合がございます。これらの情報はより良いサービス提供のため、またご本人確認や正常なサービス提供を妨害する不正行為防止のために利用いたします。
    </Content>
    <SubHeader>3.5) 位置情報</SubHeader>
    <Content>
      本サービスでは、お客様に位置情報を用いたサービスや最適なサービスを提供する等の目的で、本サービスの一部では、携帯電話等から送信される位置情報を利用することがあります。なお、携帯電話等の設定により、位置情報の送信を許可していない場合は位置情報は送信されません。
    </Content>
    <SubHeader>4.利用者情報の利用目的</SubHeader>
    <Content>
      当社は、2（お客様にご提供いただく情報および主な取り扱い）および3（本サービスの利用に伴い収集される情報および主な取り扱い）に定める記載の利用目的のほか、取得した利用者情報を以下の目的で利用いたします。なお、取得時に利用目的を限定した情報については、その範囲内でのみ使用させていただきます。
    </Content>
    <SubHeader>4.1) 本サービスの提供・改善・開発のため</SubHeader>
    <Content>
      •お客様が本サービスを円滑に利用できるようにするため
      •本サービスに関する登録の受付、本人確認など本サービスの提供、維持、保護及び改善のため
      •本サービスに関するご案内、お問い合わせなどへの対応のため
      •本サービス利用に関する統計データを作成し改善につなげるため
      •現在提供している本サービスまたは今後提供を検討している本サービスに関するアンケートやご提案を実施するため
      •今後の本サービスに関する新企画立案を行い提供するため
      •キャンペーン等の抽選および賞品や商品発送のため
      •その他本サービスに関する重要なお知らせ等、必要に応じた連絡を行うため
      •有料サービス等のご利用料金のご請求・管理等を行うため
    </Content>
    <SubHeader>4.2) 本サービスのセキュリティ、不正利用防止のため</SubHeader>
    <Content>
      •スパム行為や不正アクセス等、不正利用防止のため
      •不正利用が発生した場合などに本人確認や連絡を行うため
    </Content>
    <SubHeader>4.3) カスタマイズされたコンテンツ提供、広告配信のため</SubHeader>
    <Content>
      •お客様ごとにカスタマイズされたコンテンツを提供するため
      •本サービスに関する情報等または当社以外の事業者が広告主となる広告情報等を告知するため
      4.5).
      スポンサー等によるコンテンツを提供する場合に当該スポンサー等に対して提供するため
      本サービスでは、スポンサー又は当社の提携パートナー（以下「スポンサー等」といいます。）により提供されるスポンサーコンテンツが配信される場合があります。当社は、お客様がスポンサーコンテンツを視聴された場合、お客様の当該コンテンツへのアクセスに関する情報（スポンサーコンテンツにアクセスした人数、平均視聴時間、視聴者の属性の割合等の情報を含みます。）を当該スポンサー等に対し提供する場合があります。なお、当社は、本サービス上において、スポンサーコンテンツを配信する場合にはその旨をわかりやすく表示いたします。また、特定のお客様を識別することができる情報をスポンサー等に提供する場合には、予めお客様の同意を得るものといたします。
    </Content>
    <SubHeader>5.利用者情報の第三者提供</SubHeader>
    <Content>
      当社は、本プライバシーポリシーにおいて明示的に定める場合、他社による情報取扱いの場合、または法令で認められる場合を除いて、利用者情報をお客様の同意なく第三者に提供することはございません。
    </Content>
    <SubHeader>6.利用者情報の委託</SubHeader>
    <Content>
      当社は利用目的の達成に必要な範囲内においてお客様から取得した利用者情報の全部または一部を業務委託先（外国にある委託先を含みます。）に委託することがございます。その際、業務委託先としての適格性を十分審査するとともに、契約にあたって守秘義務に関する事項等を規定し、情報が適正に管理される体制作りを行います。
    </Content>
    <SubHeader>7.利用者の権利</SubHeader>
    <Content>
      お客様はいつでもご登録されているメールアドレスやパスワード等の利用者情報を、本サービスを提供するウェブサイトまたはスマートフォンアプリ上で確認、訂正することができます。また、アカウントを削除することができます。
      お客様は本サービス上で確認できない個人情報の開示や利用目的の通知を希望する場合、次の場合を除き、当社に対し開示等を請求することができますその場合、当社は、個人情報保護法その他の法令により開示の義務を負う場合に限って開示を行います。
      •開示することで本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
      •開示することで当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
      •開示することが法令に違反することとなる場合
      •開示の請求がご本人からであることが確認できない場合
      当社保有の個人情報の内容が事実でないとお客様が判断した場合は、個人情報の訂正・追加・削除を請求することができます。その場合、当社は利用目的の達成に必要な範囲内で、遅滞なく調査を行い、その結果に基づき当該個人情報の訂正・追加・削除を行います（ただし、当社が個人情報保護法その他の法令により訂正・追加・削除の義務を負う場合に限ります）。
      また、個人情報があらかじめ公表された利用目的の範囲を超えて取り扱われており、または偽りその他不正の手段により収集されたものであるとお客様が判断した場合は、お客様は当該個人情報の利用の停止を請求することができます。また、本プライバシーポリシーに記載なく、または個別の同意を得ることなく法令に違反して当社がお客様の個人情報を第三者へ提供しているとお客様が判断した場合は、お客様は当該個人情報の提供の停止を請求することができます。その場合、当社は当該個人情報の利用または提供を停止します（ただし、当社が個人情報保護法その他の法令により利用または提供の停止の義務を負う場合に限ります）。
    </Content>
    <SubHeader>8.退会者の情報</SubHeader>
    <Content>
      本サービスのアカウントを削除した場合、利用者情報は関連法規および社内規定に従って適切に処理いたします。
    </Content>
    <SubHeader>9.お子様の利用者情報について</SubHeader>
    <Content>
      未成年のお客様による本サービスのご利用及び利用者情報のご入力は、本プライバシーポリシーの記載に則ったお客様の個人情報の取扱いについて、親権者または後見人の方の同意がある場合に限らせていただきます。また、未成年のお客様のうち15歳以上の方は、お客様ご本人も本プライバシーポリシーをご理解いただき、当社の個人情報の取扱いに同意いただいたうえで利用者情報をご入力いただく必要があります。
    </Content>
    <SubHeader>10.本プライバシーポリシーの改定</SubHeader>
    <Content>
      当社は、当社が必要と判断する場合、本プライバシーポリシーを変更できるものとします。本プライバシーポリシーを変更する場合、変更後の本プライバシーポリシーの効力発生日および内容を当社ウェブサイト上での掲示その他の適切な方法により周知し、またはお客様に通知します。当社は、変更後の利用規約の効力発生日以降にお客様が本サービスを利用したときは、お客様は、利用規約の変更に同意したものとみなします。ただし、法令上お客様の同意が必要となるような内容の変更の場合は、当社所定の方法でお客様の同意を得るものとします。
    </Content>
    <SubHeader>11.お問い合わせ</SubHeader>
    <Content>
      本プライバシーポリシーに関してご不明な点がある場合、本サービスにおける利用者情報の取り扱いに関するご質問・苦情・ご相談等があります場合はお問い合わせメールアドレスまでお申し出ください。
      2020年12月12日制定 お問い合わせ先：support@voox.me
    </Content>
    <br />
    <br />
  </TextBox>
)

const terms = (
  <TextBox>
    <Header>VOOX利用規約</Header>
    <br />
    <Content>
      この利用規約（以下「本規約」といいます。）は、Moon Creative Lab
      Inc.（以下「当社」といいます。）が運営する音声コンテンツの制作・配信サービスである「VOOX」の利用に関する条件および当社と「VOOX」を利用するお客様（以下「お客様」といいます。）との間の権利義務関係を定めるものです。
      <br />
      <br />
      ご利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意していただく必要があります。なお、お客様が本サービスを利用した場合、当社は、本規約に定めるすべての利用条件にご同意いただいたものとみなして取り扱わせていただきます。
      <br />
      <br />
    </Content>
    <SubHeader>1.定義</SubHeader>
    <Content>
      <SubContent>本規約では、以下の用語を使用します。</SubContent>
      <SubContent>
        1.1.「本サービス」とは、当社が、当社が運営する「VOOX」を通じて提供する、次に定めるサービス（本規約に基づき本サービスの内容が変更されたときは、当該変更後のサービスを含みます。）のことをいいます。
        <br />
        ①音声コンテンツ、及び音声コンテンツに付随するその他形式のコンテンツの制作
        <br />
        ②音声コンテンツの配信
        <br />
        ③音声コンテンツの聴取
        <br />
        ④お客様によるお問い合わせへの対応
        <br />
        ⑤その他これらに付随・関連して当社が提供する機能
      </SubContent>
      <SubContent>
        1.2.「登録情報」とは、アカウント名、メールアドレス、パスワードなどお客様が本サービスを利用する目的で当社に提供する全ての情報を意味します。
      </SubContent>
      <SubContent>
        1.3.「本コンテンツ」とは、本サービス上で提供される文字、音、静止画、動画、ソフトウェアプログラム、コード等の総称(投稿情報を含みます。)を意味します。
      </SubContent>
      <SubContent>
        1.4.「外部ソーシャルネットワークサービス」とは、Facebook、Twitterその他の他の事業者が提供している当社所定のソーシャルネットワーキング・サービスで、お客様の認証、友人関係の開示、当該外部ソーシャルネットワーキング内のコンテンツの公開などの機能をもち、本サービスの実施に利用されるサービスを意味します。
      </SubContent>
      <SubContent>
        1.5.「個別利用規約」とは、本サービスに関して、本規約とは別に「規約」、「ガイドライン」、「ポリシー」などの名称で当社が配布または掲示している文書のことをいいます。
      </SubContent>
      <SubContent>
        1.6.「知的財産」とは、発明、考案、植物の新品種、意匠、著作物その他の人間の創造的活動により生み出されるもの（発見または解明がされた自然の法則または現象であって、産業上の利用可能性があるものを含む。）、商標、商号その他事業活動に用いられる商品または役務を表示するもの及び営業秘密その他の事業活動に有用な技術上または営業上の情報を意味します。
      </SubContent>
      <SubContent>
        1.7.「知的財産権」とは、特許権、実用新案権、育成者権、意匠権、著作権、商標権その他の知的財産に関して法令により定められた権利または法律上保護される利益に係る権利（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）をいいます。
      </SubContent>
    </Content>
    <SubHeader>2.規約への同意</SubHeader>
    <Content>
      <SubContent>
        2.1.本規約は、本サービスの利用に関する条件および本サービスの利用に関する当社とお客様との間の権利義務関係を定めることを目的とし、お客様と当社との間の本サービスの利用に関わる一切の関係に適用されます。
      </SubContent>
      <SubContent>
        2.2.お客様は、本規約の定めに従って本サービスを利用しなければなりません。お客様は、本規約に有効かつ取消不能な同意をしないかぎり本サービスを利用できません。
      </SubContent>
      <SubContent>
        2.3.お客様が未成年者である場合は、親権者など法定代理人の同意を得たうえで本サービスを利用してください。また、お客様が本サービスを事業者のために利用する場合は、当該事業者も本規約に同意したうえで本サービスを利用してください。本サービスを利用することによって、お客様はこれらの同意を得たものをみなされます。
      </SubContent>
      <SubContent>
        2.4.お客様は、本サービスを実際に利用することによって本規約に有効かつ取消不能な同意をしたものとみなされます。
      </SubContent>
      <SubContent>
        2.5.本サービスにおいて個別利用規約がある場合、個別利用規約は本規約の一部を構成するものとします。ただし、本利用規約の内容と、個別利用規約とが異なる場合は、個別利用規約の規定が優先して適用されるものとします。お客様は、本規約のほか個別利用規約の定めにも従って本サービスを利用しなければなりません。
      </SubContent>
    </Content>
    <SubHeader>3.規約の変更</SubHeader>
    <Content>
      当社は、当社が必要と判断する場合、本規約および個別利用規約（以下「本規約等」と総称します。）を変更できるものとします。当社は、本規約等を変更する場合、変更後の本規約等の効力発生日および内容を当社ウェブサイト上での掲示その他の適切な方法により周知し、またはお客様に通知します。変更後の本規約等の効力発生日以降にお客様が本サービスを利用したときは、お客様は、本規約等の変更に同意したものとみなします。ただし、法令上お客様の同意を要する内容の変更の場合は、当社所定の方法でお客様の同意を得るものとします。
    </Content>
    <SubHeader>
      2.2)
      お客様が、ソーシャルネットワークサービス等の外部サービスとの連携を許可する場合には、当該外部サービスの情報
    </SubHeader>
    <Content>
      お客様が、FacebookまたはTwitterなどの第三者が提供するソーシャルネットワーキングサービスと連携させて本サービスにアクセスする場合、お客様の許諾の内容に従って、性別、年齢、興味のある対象、地域やそのFacebook内の友人関係、Facebook内で「いいね！」しているものに関する情報なども入手します。これらの情報は、お客様ごとにカスタマイズされたコンテンツを提供する目的で利用いたします。
    </Content>
    <SubHeader>3.規約の変更</SubHeader>
    <Content>
      アンケートやキャンペーン（以下「キャンペーン等」といいます。）およびそれらに関わる抽選および賞品発送、ご購入商品の発送等の目的で、氏名、住所、電話番号、メールアドレス、性別、生年月日等を取得する場合がございます。
    </Content>
    <SubHeader>4.アカウント</SubHeader>
    <Content>
      <SubContent>
        4.1.本サービスへの入会を希望する方(以下「登録希望者」といいます。)は、本規約および個別利用規約（プライバシーポリシーを含みます。）。に同意した上で、所定の方法で(外部ソーシャルネットワークサービスとの連携等による場合を含みます。）、入会の申し込みをしていただきます。
      </SubContent>
      <SubContent>
        4.2.登録希望者は、当社がその申込を承諾し、ID登録が完了した時点から本サービスに登録することができます。
      </SubContent>
      <SubContent>
        4.3.当社は、本サービスに登録したお客様向けにメールで連絡事項の告知や広告その他情報提供を行います。あらかじめご了承ください。
      </SubContent>
      <SubContent>
        4.4.お客様は、本サービスの利用に際してお客様ご自身に関する情報を登録する場合、真実、正確かつ完全な情報を提供しなければならず、常に最新の情報となるよう修正しなければなりません。
      </SubContent>
      <SubContent>
        4.5.お客様は、本サービスの利用に際してパスワードを登録する場合、これを第三者に使用させてはならず、また、不正に利用されないようご自身の責任で厳重に管理しなければなりません。当社は、登録されたパスワードを利用して行なわれた一切の行為を、お客様ご本人の行為とみなすことができ、パスワードの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任はお客様が負うものとします。
      </SubContent>
      <SubContent>
        4.6.本サービスに登録したお客様は、いつでもアカウントを削除して退会することができます。
      </SubContent>
      <SubContent>
        4.7.当社は、お客様が以下の各号のいずれかの事由に該当する場合、または本規約等に違反しまたは違反するおそれがあると認めた場合、あらかじめお客様に通知することなく、アカウントを停止または削除することができ、アカウントの停止または削除にともなってお客様が被ったいずれの損害についても、当社は責任を負いかねます。
        <ul>
          <li>
            当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合
          </li>
          <li>
            未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合
          </li>
          <li>
            反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
          </li>
          <li>
            過去に当社の本規約等に違反した者またはその関係者であると当社が判断した場合
          </li>
          <li>過去に本条および次条に定める措置を受けたことがある場合</li>
          <li>
            その他、登録を適当でないと当社が判断する合理的な理由がある場合
          </li>
        </ul>
      </SubContent>
      <SubContent>
        4.8.お客様の本サービスにおけるすべての利用権は、理由を問わず、アカウントが削除された時点で消滅します。お客様が誤ってアカウントを削除した場合であっても、アカウントの復旧はできず、アカウントの削除にともなってお客様が被ったいずれの損害についても、当社は責任を負いかねます
      </SubContent>
      <SubContent>
        4.9.本サービスのアカウントは、お客様に一身専属的に帰属します。お客様の本サービスにおけるすべての利用権は、第三者に譲渡、貸与または相続させることはできません。
      </SubContent>
      <SubContent>
        4.10.本サービスに登録された情報の一部は他のお客様に公開されます。本サービスを利用するお客様は、ご自身の情報が他のお客様から閲覧可能なことを承諾するものとします。
      </SubContent>
    </Content>
    <SubHeader>5.プライバシー</SubHeader>
    <Content>
      <SubContent>
        5.1.当社は、お客様のプライバシーを尊重しています。
      </SubContent>
      <SubContent>
        5.2.当社は、お客様のプライバシー情報と個人情報を、別途当社が定めるプライバシーポリシーに従って適切に取り扱います。
      </SubContent>
      <SubContent>
        5.3.当社は、お客様から収集した情報を安全に管理するため、セキュリティに最大限の注意を払っています。
      </SubContent>
    </Content>
    <SubHeader>6.サービスの提供</SubHeader>
    <Content>
      <SubContent>
        6.1.お客様は、本サービスを利用するにあたり、必要なパーソナルコンピュータ、携帯電話機、通信機器、オペレーションシステム、通信手段および電力などを、お客様の費用と責任で用意しなければなりません。
      </SubContent>
      <SubContent>
        6.2.当社は、本サービスの全部または一部を、年齢、ご本人確認の有無、登録情報の有無、その他、当社が必要と判断する条件を満たしたお客様に限定して提供することができるものとします。
      </SubContent>
      <SubContent>
        6.3.当社は、当社が必要と判断する場合、あらかじめお客様に通知した上で、本サービスの全部または一部の内容を変更し、また、その提供を中止もしくは終了することができるものとします。ただし、当社は、緊急の場合その他やむを得ない事由がある場合は、事前の通知をせずに本サービスの一部もしくは全部についての変更または本サービスの提供を終了することができるものとし、お客様はあらかじめこれに同意するものとします。
      </SubContent>
      <SubContent>
        6.4.1前項にかかわらず、当社は、当社が必要と判断する場合、あらかじめお客様に通知することなく、いつでも、本サービスの全部または一部について、軽微な内容の変更をすることができるものとします。
      </SubContent>
      <SubContent>
        6.4.2当社は、以下の各号のいずれかに該当する場合、利用者に事前に通知することなく一時的に本サービスの全部または一部を停止・中断することができます。かかる停止・中断によって利用者に損害が発生した場合であっても、当社は一切の責任を負わないものとします。
        <ul>
          <li>
            サーバ、通信回線、その他の設備の故障、障害の発生またはその他の理由により本サービスの提供ができなくなった場合
          </li>
          <li>
            本サービスの提供に必要なシステム（サーバ、通信回線や電源、それらを収容する建造物等を含みます。）の保守・点検、修理、またはシステム障害の対応が必要となる場合
          </li>
          <li>火災、停電等により本サービスの提供ができなくなった場合</li>
          <li>
            戦争、騒乱、天災地変、地震、噴火、津波、争議行為その他の不可抗力により本サービスの提供ができなくなった場合
          </li>
          <li>
            その他当社が本サービスを一時的に停止・中断することが必要と判断した場合
          </li>
        </ul>
      </SubContent>
      <SubContent>
        6.5.本サービスは、お客様が、当社が指定する課金方法により、当社所定の利用料金を支払うことで利用できます。支払い済みの料金は、いかなる理由があっても返金することができません。当社が指定する課金方法とは、iTunes
        Storeによる決済、又はGooglePlayによる決済をいいます。ただし、一部のコンテンツ、及び当社が別途定めた場合においては、無償で本サービス又は本サービスの一部を利用することができます。
      </SubContent>
      <SubContent>
        6.6.
        お客様は、本規約のほか、自己が利用する各支払方法にかかる決済事業者等が定める利用規約等の利用条件を遵守するものとします。利用者が当該義務に違反する場合、決済事業者等による決済処理が行われず、または決済処理が取り消される場合があります。これらの支払方法にかかる利用条件等については、各決済事業者等の定める利用条件をご確認ください。サービス利用料の決済に関し、お客様と通信事業者、決済事業者等との間で紛争が生じた場合は、お客様の責任においてこれを解決していただくものとし、当社は一切責任を負わないものとします。
      </SubContent>
      <SubContent>
        6.7.本サービスの価格、および課金方法は、事前の予告なく変更されることがあります。
      </SubContent>
    </Content>
    <SubHeader>7. コンテンツの取り扱い</SubHeader>
    <Content>
      <SubContent>
        7.1.お客様は、本コンテンツを当社の定める範囲内でのみ使用することができるものとします。
      </SubContent>
      <SubContent>
        7.2.本コンテンツに関する権利は当社が有しており、お客様に対し、当社が有する知的財産権の実施または使用許諾をするものではありません。なお、本サービスを通じてRSSに基づいて表示・配信される第三者作成に係る文字、音、静止画、動画、説明文その他のコンテンツ（以下「RSSコンテンツ」といいます。）の知的財産権の帰属は、当該RSSの配信元の定め及び法令によるものとします。お客様は、RSSコンテンツの利用について、当該RSSの配信元が定める条件を遵守するものとします。
      </SubContent>
      <SubContent>
        7.3.お客様は、いかなる方法によっても本コンテンツの複製、送信、譲渡(利用者動詞の売買も含みます)、貸与、翻訳、翻案、無断で転載、二次利用、営利目的の使用、改変、逆アセンブル、リバースエンジニアリングなどを行ってはならないものとします。
      </SubContent>
      <SubContent>
        7.4.前項に関わらず、退会などにより利用者が利用者資格を喪失した場合は、提供された本コンテンツの使用権も消滅するものとします。
      </SubContent>
    </Content>
    <SubHeader>8.禁止事項</SubHeader>
    <Content>
      <SubContent>
        お客様は、本サービスの利用に際して、以下に記載することを行なってはなりません。
      </SubContent>
      <SubContent>
        8.1.法令、裁判所の判決、決定もしくは命令、または法令上拘束力のある行政措置に違反する行為。
      </SubContent>
      <SubContent>
        8.2.公の秩序または善良の風俗を害するおそれのある行為。
      </SubContent>
      <SubContent>
        8.3.当社または第三者の知的財産権等、名誉権、プライバシー権、その他法令上または契約上の権利を侵害する行為。
      </SubContent>
      <SubContent>
        8.4.過度に暴力的な表現、露骨な性的表現、人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現、自殺、自傷行為、薬物乱用を誘引または助長する表現、その他反社会的な内容を含み他人に不快感を与える表現を、投稿または送信する行為。
      </SubContent>
      <SubContent>
        8.5.当社または第三者になりすます行為または意図的に虚偽の情報を流布させる行為。
      </SubContent>
      <SubContent>
        8.6.同一または類似のメッセージを不特定多数のお客様に送信する行為（当社の認めたものを除きます。）、他のお客様を無差別に友だちまたはグループトークに追加する行為、その他当社がスパムと判断する行為。
      </SubContent>
      <SubContent>
        8.7.当社が定める方法以外の方法で、本コンテンツの利用権を、現金、財物その他の経済上の利益と交換する行為。
      </SubContent>
      <SubContent>
        8.8.営業、宣伝、広告、勧誘、その他営利を目的とする行為（当社の認めたものを除きます。）、性行為やわいせつな行為を目的とする行為、面識のない異性との出会いや交際を目的とする行為、他のお客様に対する嫌がらせや誹謗中傷を目的とする行為、その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為。
      </SubContent>
      <SubContent>
        8.9.反社会的勢力に対する利益供与その他の協力行為。
      </SubContent>
      <SubContent>8.10.宗教活動または宗教団体への勧誘行為。</SubContent>
      <SubContent>
        8.11.他人の個人情報、登録情報、利用履歴情報などを、不正に収集、開示または提供する行為。
      </SubContent>
      <SubContent>
        8.12.本サービスのサーバやネットワークシステムに支障を与える行為、BOT、チートツール、その他の技術的手段を利用してサービスを不正に操作する行為、本サービスの不具合を意図的に利用する行為、同様の質問を必要以上に繰り返す等、当社に対し不当な問い合わせまたは要求をする行為、その他当社による本サービスの運営または他のお客様による本サービスの利用を妨害し、これらに支障を与える行為。
      </SubContent>
      <SubContent>
        8.13.本規約に違反する行為または本サービスの趣旨目的に反する行為。
      </SubContent>
      <SubContent>
        8.14.前各号のいずれかに該当する行為を援助または助長する行為。
      </SubContent>
      <SubContent>8.15.前各号のいずれかに該当する行為を試みること</SubContent>
      <SubContent>
        8.16.その他、当社が不適当と判断する合理的な理由がある行為。
      </SubContent>
    </Content>
    <SubHeader>9.お客様の責任</SubHeader>
    <Content>
      <SubContent>
        9.1.お客様は、お客様ご自身の責任において本サービスを利用するものとし、本サービスにおいて行った一切の行為およびその結果について一切の責任を負うものとします。
      </SubContent>
      <SubContent>
        9.2.当社は、お客様が本規約に違反して本サービスを利用していると認めた場合、当社が必要かつ適切と判断する措置（アカウントの停止もしくは削除、本サービスの全部または一部の利用の停止等を含みますがこれらに限られません。）を講じることができ、これらの措置にともなってお客様が被ったいずれの損害についても、当社は責任を負いかねます。ただし、当社は、かかる違反を防止または是正する義務を負いません。
      </SubContent>
      <SubContent>
        9.3.お客様は、本サービスを利用したことに起因して（当社がかかる利用を原因とするクレームを第三者より受けた場合を含みます。）、当社が直接的もしくは間接的に何らかの損害（弁護士費用の負担を含みます。）を被った場合、当社の請求にしたがって直ちにこれを補償しなければなりません。
      </SubContent>
    </Content>
    <SubHeader>10.当社の免責</SubHeader>
    <Content>
      <SubContent>
        10.1.当社は、本サービス（本コンテンツを含みます。）に事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しません。当社は、お客様に対して、かかる瑕疵を除去して本サービスを提供する義務を負いません。
      </SubContent>
      <SubContent>
        10.2.当社は、本サービスに起因してお客様に生じたあらゆる損害について一切の責任を負いません。ただし、本サービスに関する当社とお客様との間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。
      </SubContent>
      <SubContent>
        10.3.上記11.2.ただし書に定める場合であっても、当社がお客様に対して負う責任の範囲は、当社の故意または重過失による場合を除き、お客様に現実に生じた通常かつ直接の損害（逸失利益を除きます。）に限られるものとします。
      </SubContent>
    </Content>
    <SubHeader>11.連絡方法</SubHeader>
    <Content>
      <SubContent>
        11.1.本サービスに関する当社からお客様への連絡は、当社が運営するウェブサイトまたはアプリ内の適宜の場所への掲示その他、当社が適当と判断する方法により行ないます。
      </SubContent>
      <SubContent>
        11.2.本サービスに関するお客様から当社への連絡は、当社が運営するウェブサイトまたはアプリ内の適宜の場所に設置するお問い合わせメールアドレスまたは当社が指定する方法により行っていただきます。
      </SubContent>
    </Content>
    <SubHeader>12.サービス利用契約上の地位の譲渡等</SubHeader>
    <Content>
      <SubContent>
        12.1.お客様は、当社の書面による事前の承諾なく、本サービスに係る契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
      </SubContent>
      <SubContent>
        12.2.当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い本サービスに係る契約上の地位、本規約に基づく権利および義務ならびにお客様の登録事項その他の情報を当該事業譲渡の譲受人に譲渡することができるものとし、お客様は、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
      </SubContent>
    </Content>
    <SubHeader>13.分離可能性</SubHeader>
    <Content>
      本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
    </Content>
    <SubHeader>14.準拠法、裁判管轄</SubHeader>
    <Content>
      本規約は日本語を正文とし、その準拠法は日本法とします。本サービスに起因または関連してお客様と当社との間に生じた紛争については東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
    </Content>
    <Content>2020年12月12日制定</Content>
    <br />
    <br />
  </TextBox>
)

const tokusho = (
  <TextBox>
    <Header>特定商取引法に基づく表記</Header>
    <br />
    <Content>
      Moon Creative Lab
      Inc.は、「特定商取引に関する法律」第11条(通信販売についての広告)に基づき以下のとおり明示します。
      <br />
      <br />
    </Content>
    <SubHeader>サービス名称</SubHeader>
    <Content>VOOXアプリ内音声コンテンツのサブスクリプションサービス</Content>
    <SubHeader>事業者</SubHeader>
    <Content>Moon Creative Lab Inc.</Content>
    <SubHeader>代表者</SubHeader>
    <Content>横山 賀一</Content>
    <SubHeader>所在地</SubHeader>
    <Content>660 High Street, Palo Alto, California, 94301 USA</Content>
    <SubHeader>日本における所在地</SubHeader>
    <Content>
      東京都港区北青山3丁目10番5号
      <br />
      SPRING TERRACE OMOTESANDO
    </Content>
    <SubHeader>URL</SubHeader>
    <Content>
      <a href="https://www.mooncreativelab.com/">
        https://www.mooncreativelab.com/
      </a>
      <br />
      <a href="https://voox.me/">https://voox.me/</a>
    </Content>
    <SubHeader>E-mail</SubHeader>
    <Content>
      support@voox.me
      <br />
      ※お取引やサービスについてのお問い合わせは電話では受け付けておりません。大変申し訳ありませんが、お問い合わせについては個人情報保護などのためアプリ内の「お問い合わせ」フォームよりご連絡をいただき対応させていただいております。連絡先電話番号についても、上記メールアドレスにお問い合わせ頂ければ、遅滞なく開示致します。ご不便をおかけいたしますが、ご理解ご協力をよろしくお願いいたします。
    </Content>
    <SubHeader>業務内容</SubHeader>
    <Content>VOOXアプリを通じて音声コンテンツを制作・配信</Content>
    <SubHeader>利用代金</SubHeader>
    <Content>
      サブスクリプションサービス（月額サービス）の場合
      <br />
      <a href="https://voox.me/">https://voox.me/</a>
    </Content>
    <SubHeader>商品等の引き渡し時期等</SubHeader>
    <Content>
      購入に関するページに特別な定めを置いている場合を除き、購入取引完了後、直ちにご利用頂けます。
    </Content>
    <SubHeader>お支払い方法</SubHeader>
    <Content>
      お支払い方法：Apple Pay、Google pay
      <br />
      お支払い時期：プレミアムサービスのご利用開始時に、1ヶ月分の利用料金を先払いにてお支払いいただきます。自動更新された場合には、以後も同様とします。
    </Content>
    <SubHeader>利用代金以外にお客様に発生する金銭</SubHeader>
    <Content>
      VOOXアプリの閲覧、コンテンツ購入、ソフトウェアのダウンロード等に必要となるインターネット接続料金、通信料金等はお客様の負担となります。
      <br />
      それぞれの料金は、お客様がご利用のインターネットプロバイダーまたは携帯電話会社にお問い合わせください。
    </Content>
    <SubHeader>返品・キャンセルについての特約事項</SubHeader>
    <Content>
      いつでも解約手続きを行うことができますが、予定された利用期間の途中において解約手続きを行った場合でも、契約満了日までの料金が発生し、日割精算等による返金を含めた一切の返金は行われません。その場合、サービスも契約満了日まで提供されます。
    </Content>
    <SubHeader>動作環境</SubHeader>
    <Content>
      VOOXの動作環境は下記とします。
      <br />
      スマートフォン
      <br />
      iOS
      <br />
      OS：iOS 12.0 以降
      <br />
      アプリバージョン：AppStore で提供している最新バージョン
      <br />
      ※iOS 12より前のバージョンは、ご利用いただけません。
      <br />
      <br />
      Android
      <br />
      OS：Android 6.0 以降
      <br />
      アプリバージョン：Google Play で提供している最新バージョン
      <br />
      ※Android 6.0より前のバージョンは、ご利用いただけません。
    </Content>
    <br />
    <br />
  </TextBox>
)

const content = modalType => {
  if (modalType == ModalType.privacy) {
    return privacy;
  } else if (modalType == ModalType.terms) {
    return terms;
  } else if (modalType == ModalType.tokusho) {
    return tokusho;
  }
  return privacy;
}


const Modal = ({ setModalState, modalState, sizes }) => {
  const modalRef = React.createRef();
  return (
    <div style={{ position: "relative" }}>
      <Container sizes={sizes} ref={modalRef}>
        {content(modalState)}
      </Container>

      <CloseButton
        onClick={() => {
          modalRef.current.scrollTop = 0;
          setModalState(null);
        }}
        sizes={sizes}
      >
        ✕
      </CloseButton>
    </div>
  )
}

export default Modal;
